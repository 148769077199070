<template>
    <zw-sidebar @shown="shown"
                :title="$t('common.form.title.'+this.payload.title)"
    >
        <ValidationObserver tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-switch-group v-model="offering.newCustomer"
                                         name="offering.newCustomer"
                        ></zw-switch-group>
                    </b-col>

                    <template v-if="offering.newCustomer != 0">
                        <b-col sm="12">
                            <input type="hidden" v-model="offering.customerType">

                            <b-form-radio v-for="(option,key) in getCustomerForms()"
                                          :key="key"
                                          v-model="customerForm"
                                          name="customerForm"
                                          :value="option"
                                          :inline="true"
                                          size="lg"
                            >{{ $t('customer.label.radio.' + option) }}
                            </b-form-radio>

                            <template v-if="customerForm == 'company'">
                                <zw-input-group v-model="offering.customerCompany"
                                                name="offering.customerCompany"
                                ></zw-input-group>
                            </template>
                            <template v-else>
                                <zw-input-group v-model="offering.customerFirstName"
                                                name="offering.customerFirstName"
                                                validate="required"
                                ></zw-input-group>
                                <zw-input-group v-model="offering.customerLastName"
                                                name="offering.customerLastName"
                                                validate="required"
                                ></zw-input-group>
                            </template>

                            <zw-input-group v-model="offering.customerStreet"
                                            name="offering.customerStreet"
                                            validate="required"
                            ></zw-input-group>
                            <zw-input-group v-model="offering.customerPostcode"
                                            name="offering.customerPostcode"
                                            validate="required"
                            ></zw-input-group>
                            <zw-input-group v-model="offering.customerCity"
                                            name="offering.customerCity"
                                            validate="required"
                            ></zw-input-group>
                        </b-col>
                    </template>
                    <template v-else>
                        <b-col>
                            <zw-model-selector-group v-model="offering.customer"
                                                     name="offering.customer"
                                                     model-type="customer"
                                                     validate="required"
                                                     :callback="customerSelected"
                                                     :additionalParams="{'type':payload.type}"
                            />
                        </b-col>
                        <!--                        <b-col sm="12">-->
                        <!--                            <search-field v-model="offering.customer"-->
                        <!--                                          name="offering.customer"-->
                        <!--                                          validate="required"-->
                        <!--                                          :url="customersSearchUrl"-->
                        <!--                                          :callback="customerSelected"-->
                        <!--                                          @input-object="customerSelected"-->
                        <!--                                          :additionalParams="{'type':this.payload.type}"-->
                        <!--                                          inputClass="modal_open_focus"-->
                        <!--                            ></search-field>-->
                        <!--                        </b-col>-->

                        <b-col sm="12">
                            <zw-select-group name="offering.contactPerson"
                                             v-model="offering.contactPerson"
                                             :options="contactPersons"
                                             validate="required"
                                             text-field="fullName"
                                             value-field="id"
                                             size="md"
                            ></zw-select-group>
                        </b-col>
                    </template>

                    <b-col sm="12">
                        <zw-input-group v-model="offering.projectName"
                                        name="offering.projectName"
                                        size="md"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-input-group v-model="offering.offeringName"
                                        name="offering.offeringName"
                                        size="md"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <custom-slot id="new_project_form" :base="getThis()" :time-out="0"></custom-slot>
                <b-row align-h="end">
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import ValidateMixin from './../../mixins/validate'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'project-form',
    components: {ZwModelSelectorGroup},
    mixins: [ValidateMixin, commonSave],
    data() {
        return {
            loading: true,
            customersSearchUrl: window.apiUrl + '/customer-search',
            payload: {},
            contactPersons: [],
            customerForm: null,
            offering: {
                customer: null,
                contactPerson: null,
                projectName: null,
                offeringName: null,
                newCustomer: 0,
                customerType: null,
                customerFirstName: null,
                customerLastName: null,
                customerCompany: null,
                customerStreet: null,
                customerCity: null,
                customerPostcode: null,
            }
        }
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPersons']),
        ...mapGetters('CommonData', ['getOfferingPeriods', 'getCustomerForms', 'getSettings']),
        ...mapGetters('Customer', ['getCustomer']),
        getThis() {
            return this
        },
        shown() {
            let settings = this.getSettings()
            this.customerForm = settings.default_customer_form

            if (this.payload.customer) {
                this.$set(this.offering, 'customer', this.payload.customer.id)
                this.loading = false
            } else if (this.payload.customerId) {
                this.$store.dispatch('Customer/fetchCustomer', this.payload.customerId)
                    .then(() => {
                        this.$set(this.offering, 'customer', this.getCustomer().id)
                        this.loading = false
                    })
            } else {
                this.loading = false
            }
            this.payload.title = 'newOffering'
            if (this.payload.type == 'provider') {
                this.offering.customerType = this.payload.type;
                this.payload.title = 'newProvider';
            }
        },
        customerSelected(customer) {
            this.$store.dispatch('ContactPerson/fetchContactPersonsList', customer.id)
                .then(() => {
                    this.contactPersons = this.getContactPersons()
                    this.offering.contactPerson = this.getContactPersons()[0].id
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Offering/createOffering', {
                        'customerId': this.offering.customer,
                        'contactPersonId': this.offering.contactPerson,
                        'name': this.offering.projectName,
                        'offeringName': this.offering.offeringName,
                        'newCustomer': this.offering.newCustomer,
                        'customerType': this.offering.customerType,
                        'customerFirstName': this.offering.customerFirstName,
                        'customerLastName': this.offering.customerLastName,
                        'customerCompany': this.offering.customerCompany,
                        'customerStreet': this.offering.customerStreet,
                        'customerCity': this.offering.customerCity,
                        'customerPostcode': this.offering.customerPostcode,
                        'allForm': this.offering,
                    }).then((response) => {
                        this.$root.showMessage(
                            this.$t('common.form.title.offering'),
                            this.$t('common.toasts.offeringCreated'),
                            'success')

                        this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])

                        this.$root.$children[0].openModal('offering-modal', {
                            id: response.data.id,
                            formType: response.data.type
                        });
                    })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
}
</script>